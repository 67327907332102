import { ControlModal, DisplayLoading, HideLoading, BtnCloseModal, BtnForceCloseModal, BodyLoading, UrlMenu } from "../js/scripts/functions"
import { Service } from '../services/auth-service'
import { Constant, UrlPage } from '../js/scripts/constants'
import { Session } from '../js/scripts/session'
import { languages } from "../src/public/js/languages";
//import packageJSON from '../package.json' assert {type: 'json'};

//##############################################################################################################
// ARQUIVO DE RENDERIZAÇÃO DE PÁGINA
// Página: index
// Detalhes: Página inicial da aplicação - página de login
//##############################################################################################################

const MapaCepNumeroPage = UrlMenu("mapa")

const loadDOM = async (event) => {
      document.title = Constant.TITLE;

      document.querySelector("#hbemvindo").textContent = languages[Session.Lang].bem_vindo;
      document.querySelector("#btnLogin").textContent = languages[Session.Lang].login;
      document.querySelector("#txtEmail").placeholder = languages[Session.Lang].email;
      document.querySelector("#txtSenha").placeholder = languages[Session.Lang].senha;

      BodyLoading(false);

      const currentPath = window.location.pathname;

      // aqui é validado se o usuário já possui login ativo na session local
      // caso tenha, é redirecionado para a página de mapa
      if (Session.isSessionValid()) {
            if ((currentPath == "/" + __VITE_BASE__) ||
                  (currentPath == "/" + __VITE_BASE__ + "/") ||
                  (currentPath == "/" + __VITE_BASE__ + "/index.html")) {
                  if (Session.DataPrimeiroAcesso != null) {
                        window.location.href = MapaCepNumeroPage
                  }
            }
      }

      const form = document.getElementById('frmLogin');

      form.addEventListener('submit', (event) => {
            event.preventDefault();

            var txtEmail = document.querySelector("#txtEmail").value;
            var txtSenha = document.querySelector("#txtSenha").value;

            BodyLoading(true);

            Service.EfetuarLogin(txtEmail, txtSenha)
                  .then((data) => {
                        if (data == 404) {
                              BodyLoading(false);
                              var menu = document.querySelector("#message");

                              var html = `
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                          <strong>${languages[Session.Lang].tit_credenciais_incorretas}</strong> ${languages[Session.Lang].desc_credenciais_incorretas}
                                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                          </button>
                                    </div>
                                    `;

                              menu.innerHTML = html;
                        } else if (data == 400 || data == 500) {
                              BodyLoading(false);
                              var menu = document.querySelector("#message");

                              var html = `
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                          <strong>Ocorreu um erro inesperado.</strong> Favor entre em contato com o administrador do sistema
                                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                          </button>
                                    </div>
                                    `;

                              menu.innerHTML = html;
                        } else if (data == 408 || data == 409) {
                              BodyLoading(false);
                              var menu = document.querySelector("#message");

                              var html = `
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                          <strong>${languages[Session.Lang].tit_erro_inesperado}</strong> ${languages[Session.Lang].desc_erro_inesperado}
                                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                          <     span aria-hidden="true">&times;</span>
                                          </button>
                                    </div>
                                    `;

                              menu.innerHTML = html;
                        }
                        else {
                              localStorage.setItem('_temp_auth_user', JSON.stringify(data));

                              if (data){
                                    // Valida se usuário já realizou o primeiro login
                                    if (data.dataPrimeiroAcesso != null) {
                                          window.location.href = '/' + __VITE_BASE__ + '/autenticacao'
                                    }
                                    else {
                                          window.location.href = '/' + __VITE_BASE__ + '/autenticacao/primeiro-acesso'
                                    }
                              }
                        }

                  })
                  .then((data) => {
                        return data
                  })
                  .catch((err) => {
                        BodyLoading(false)
                  })
      });

      const lblEsqueciMinhaSenha = document.getElementById('lblEsqueciMinhaSenha')

      lblEsqueciMinhaSenha.addEventListener('click', (event) => {
            window.location.href = UrlMenu("autenticacao/verificar-usuario")
      });

};

function isObject(obj) {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
};

window.addEventListener("load", loadDOM);